<template>
  <q-form ref="editForm">
    <c-card title="조사계획" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline>
          <!-- && !isInvestY -->
          <c-btn
            v-if="!disabled&& !isInvestY"
            :url="updateUrl"
            :isSubmit="isSave"
            :param="accidentInfo"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveAccident"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-9 col-sm-9 col-md-3 col-lg-3 col-xl-3">
          <c-radio
            :required="true" 
            :editable="editable"
            :disabled="disabled || accidentInfo.accidentStatusCd === 'ISPC000004'"
            :comboItems="checkTargetItems"
            :range="true"
            label="조사대상 여부"
            name="investigationFlag"
            v-model="accidentInfo.investigationFlag"
            @datachange="datachange"
            >
          </c-radio>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :editable="editable"
            :disabled="disabled || isInvestY"
            :range="true"
            label="기간"
            name="investigationDate"
            v-model="accidentInfo.investigationDate">
          </c-datepicker>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-6 col-lg-6 col-xl-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled || isInvestY"
            label="계획"
            :rows="4"
            name="investigationPlan"
            v-model="accidentInfo.investigationPlan">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <c-table
        ref="table"
        title="사고조사팀"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="accidentInfo.accidentInvestigationTeamModelList"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="editable && !disabled && !isInvestY"
        selection="multiple"
        rowKey="researcherId"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <c-btn v-if="editable && !disabled && !isInvestY" :showLoading="false" label="추가" icon="add" @btnClicked="add" />
            <c-btn v-if="editable && !disabled && !isInvestY && accidentInfo.accidentInvestigationTeamModelList.length > 0" :showLoading="false"  label="제외" icon="remove" @btnClicked="remove" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-accident-reception-plan',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        count: 0,
      }),
    },
  },
  data() {
    return {
      accidentInfo: {
        iimAccidentId: '',
        investigationFlag: '',
        investigationDate: [],
        investigationPlan: '',
        reportDate: '',
        occurrenceCause: '',
        accidentDamageHistory: '',
        emergencyMeasures: '',
        materialLossHistory: '',
        materialLossAmount: '',
        enviromentLeakInformation: '',
        enviromentImpactHistory: '',
        accidentStatusCd: '',

        accidentInvestigationTeamModelList: [],
        deleteAccidentInvestigationTeamModelList: [],
        accidentVictimModelList: [],
        deleteAccidentVictimModelList: [],
      },
      grid: {
        columns: [
          {
            name: 'researcherDeptName',
            field: 'researcherDeptName',
            label: '부서',
            style: 'width:20%',
            align: 'center',
            sortable: false
          },
          {
            name: 'researcherName',
            field: 'researcherName',
            label: '이름',
            style: 'width:20%',
            align: 'center',
            sortable: false
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            style: 'width:20%',
            align: 'center',
            sortable: false
          },
          {
            name: 'researcherPosition',
            field: 'researcherPosition',
            label: '직급',
            style: 'width:20%',
            align: 'center',
            sortable: false
          },
          // {
          //   name: 'insideOutsideTypeCd',
          //   field: 'insideOutsideTypeCd',
          //   label: '내/외부',
          //   align: 'center',
          //   style: 'width:100px',
          //   type: 'select',
          //   comboItems: [
          //     { code: 'Y', codeName: '내부직원' },
          //     { code: 'N', codeName: '외부직원' },
          //   ],
          //   sortable: false,
          // },
          {
            name: 'researcherRole',
            field: 'researcherRole',
            label: '역할',
            type: "text",
            // style: 'width:400px',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
        height: '300px'
      },
      checkTargetItems: [
        { code: 'Y', codeName: this.$language('대상') },
        { code: 'N', codeName: this.$language('비대상') },
      ],
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: true,
      searchUrl: '',
      isSave: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: 'transactionConfig.sop.iim.accident.process.investigation.update.url',
      isInvestY: false,
    };
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.popupParam.stepCd) && (this.popupParam.stepCd === 'ISPC000005')
    }
  },
  watch: {
    'accidentInfo'() {
      this.$emit('transInfo', this.accidentInfo);
    },
    'popupParam.count'() {
      this.getVictimDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.process.investigation.get.url;
      this.insertUrl = transactionConfig.sop.iim.accident.process.investigation.insert.url;
      this.updateUrl = transactionConfig.sop.iim.accident.process.investigation.update.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.process.delete.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.accidentInfo = _result.data
          if (_result.data.investigationDate) {
            this.accidentInfo.investigationDate = _result.data.investigationDate.split(',');
          }
          this.$_.forEach(this.accidentInfo.accidentVictimModelList, _item => {
            if (_item.expectTreatmentDate) {
              _item.expectTreatmentDate = _item.expectTreatmentDate.split(',')
            }
          })
          if (_result.data.investigationFlag === 'N') {
            this.isInvestY = true;
            this.$emit('isInvestY', this.isInvestY)
          }
        },);
      }
    },
    getVictimDetail() {
      if (this.popupParam.iimAccidentId) {
        this.$http.url = this.$format(selectConfig.sop.iim.accident.process.investigation.victim.get.url, this.popupParam.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.accidentInfo.accidentVictimModelList = this.$_.clone(_result.data)
          if (_result.data) {
            this.$_.forEach(this.accidentInfo.accidentVictimModelList, _item => {
              if (_item.expectTreatmentDate) {
                _item.expectTreatmentDate = _item.expectTreatmentDate.split(',')
              }
            })
          }
        },);
      }
    },
    add() {
      this.popupOptions.title = '사고자 검색'; 
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.accidentInfo.accidentInvestigationTeamModelList, {
            researcherId: _item.userId,
          });
          if (index === -1) {
            this.accidentInfo.accidentInvestigationTeamModelList.splice(0, 0, {
              iimAccidentId: this.popupParam.iimAccidentId,
              researcherNo: uid(),
              researcherDeptName: _item.deptName,
              researcherId: _item.userId,
              researcherName: _item.userName,
              birthDate: _item.birthDate,
              researcherRole: '',
              researcherPosition: _item.spotName,
              insideOutsideTypeCd: null,
              editFlag: 'C'
            })
          }
        })
      }
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.accidentInfo.accidentInvestigationTeamModelList = this.$_.reject(this.accidentInfo.accidentInvestigationTeamModelList, { researcherId: item.researcherId })
        })
        this.$_.forEach(selectData, item => {
          if (!this.accidentInfo.deleteAccidentInvestigationTeamModelList) {
              this.accidentInfo.deleteAccidentInvestigationTeamModelList = []
          }
          if (this.$_.findIndex(this.accidentInfo.deleteAccidentInvestigationTeamModelList, { researcherId: item.researcherId }) === -1
          && item.editFlag !== 'C') {
            this.accidentInfo.deleteAccidentInvestigationTeamModelList.push(item)
          }
          this.accidentInfo.accidentInvestigationTeamModelList = this.$_.reject(this.accidentInfo.accidentInvestigationTeamModelList, 
          { researcherId: item.researcherId })
        })
      }
    },
    saveAccident() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              if (this.accidentInfo.investigationDate) {
                this.accidentInfo.investigationDate = this.accidentInfo.investigationDate.join();
              }
              if (this.accidentInfo.accidentVictimModelList) {
                this.$_.forEach(this.accidentInfo.accidentVictimModelList, _item => {
                  if (_item.expectTreatmentDate)  _item.expectTreatmentDate = _item.expectTreatmentDate.join();
                })
              }
              this.accidentInfo.regUserId = this.$store.getters.user.userId
              this.accidentInfo.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.iimAccidentId = result.data;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();

      this.$emit('currentStep')
    },
    datachange() {
      if (this.accidentInfo.investigationFlag === 'N' && !this.isInvestY) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '조사정보가 초기화 됩니다. 변경하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.accidentInfo.deleteAccidentInvestigationTeamModelList = this.$_.clone(this.accidentInfo.accidentInvestigationTeamModelList); // 조사인원 데이터
              this.accidentInfo.accidentInvestigationTeamModelList = []; // 조사인원 데이터
              this.accidentInfo.investigationDate = null;
              this.accidentInfo.investigationPlan = '';
              this.accidentInfo.reportDate = '';
              this.accidentInfo.occurrenceCause = '';
              this.accidentInfo.emergencyMeasures = '';
              this.accidentInfo.accidentDamageHistory = '';
              this.accidentInfo.materialLossHistory = '';
              this.accidentInfo.materialLossAmount = '';
              this.accidentInfo.enviromentLeakInformation = '';
              this.accidentInfo.enviromentImpactHistory = '';
              if (this.accidentInfo.investigationDate) {
                this.accidentInfo.investigationDate = this.accidentInfo.investigationDate.join();
              }
              if (this.accidentInfo.accidentVictimModelList) {
                this.$_.forEach(this.accidentInfo.accidentVictimModelList, _item => {
                  if (_item.expectTreatmentDate)  _item.expectTreatmentDate = _item.expectTreatmentDate.join();
                })
              }
              this.$http.url = transactionConfig.sop.iim.accident.process.investigation.update.url;
              this.$http.type = 'PUT';
              this.$http.param = this.accidentInfo
              this.$http.request(() => {
                this.$http.url = transactionConfig.sop.iim.accident.process.investigation.update.status.url
                this.$http.type = 'PUT';
                this.$http.param = {
                  investigationFlag: this.accidentInfo.investigationFlag,
                  iimAccidentId: this.accidentInfo.iimAccidentId,
                  accidentStatusCd: 'ISPC000002',
                }
                this.$http.request(() => {
                  
                  this.isInvestY = false;
                  this.isInvestY = true;
                  this.$emit('isInvestY', this.isInvestY)
                  this.getDetail();
                },);
              },);
              
            },
            // 취소 callback 함수
            cancelCallback: () => {
              this.accidentInfo.investigationFlag = 'Y';
              this.isInvestY = false;
              this.$emit('isInvestY', this.isInvestY)
            },
          });
          // 상태변경
        } else if(this.accidentInfo.investigationFlag === 'Y') {
          this.$http.url = transactionConfig.sop.iim.accident.process.investigation.update.status.url
          this.$http.type = 'PUT';
          this.$http.param = {
            investigationFlag: this.accidentInfo.investigationFlag,
            iimAccidentId: this.accidentInfo.iimAccidentId,
            accidentStatusCd: 'ISPC000003',
          }
          this.$http.request(() => {
          },);
          this.isInvestY = false;
          this.$emit('isInvestY', this.isInvestY)
        }
    }
  }
};
</script>
